
































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { assetsServices } from "@/services/assets.service";
import {
  DataListAssetBook,
  RequestCategoryAsset,
  RequestAssetCategory,
  RequestCreateSegment,
  ResponseListSegment,
  CustomListCategory,
  CustomDataSourceListAssetCategory,
  CustomDataSourceAssetCategories,
  RequestCreateAssetCategory,
  AccumulatedDepreciationAccount,
} from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { debounceProcess } from "@/helpers/debounce";
import { settingsServices } from "@/services/settings.service";
import {
  ResponseListOfCoa,
  ResponseListOfMasterUom,
} from "@/models/interface/settings.interface";
import { masterServices } from "@/services/master.service";
import { numericOnly } from "@/validator/globalvalidator";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { ResponsePagination } from "@/models/constant/interface/common.interface";

interface CustomCoaCategory {
  name: string;
  id: string;
}

export default Vue.extend({
  data() {
    this.getListEquipment = debounceProcess(this.getListEquipment, 200);
    this.getListCategories = debounceProcess(this.getListCategories, 200);
    this.handleSearchSelectTable = debounceProcess(
      this.handleSearchSelectTable,
      200
    );
    return {
      selectedRowKeys: [] as number[],
      InputNumberOnly: numericOnly,
      loadingListAssetBook: false as boolean,
      loadingListCategories: false as boolean,
      idForUpdate: "" as string,
      visible: false as boolean,
      search: "" as string,
      active: true as boolean,
      mode: "Create" as "Edit" | "Create",
      activeTabPane: 1 as number,
      firstSearch: false as boolean,
      limit: 10 as number,
      limitAssetCategories: 10 as number,
      page: 1 as number,
      pageAssetCategories: 1 as number,
      totalData: 0 as number,
      totalElementsAssetCategories: 1 as number,
      dataBookType: [] as ResponseListMaster[],
      dataBookMethod: [] as ResponseListMaster[],
      dataCoa: {} as ResponseListOfCoa,
      dataEquipment: {} as ResponseListSegment,
      dataListCategory: [] as CustomListCategory[],
      depreciate: true as boolean,
      loading: false as boolean,
      loading2: false as boolean,
      loadingEquipment: false as boolean,
      loadingTable: false as boolean,
      loadingTableAssetCategories: false as boolean,
      form: this.$form.createForm(this, { name: "category" }),
      formEquipment: this.$form.createForm(this, { name: "equipment" }),
      formAssetCategories: this.$form.createForm(this, {
        name: "assetCategories",
      }),
      formAssetCategoriesModal: this.$form.createForm(this, {
        name: "assetCategoriesModal",
      }),
      formRulesEquipment: {
        equipment: {
          label: "lbl_equipment",
          name: "equipment",
          placeholder: "lbl_equipment_placeholder",
          decorator: [
            "equipment",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      formRulesAssetCategories: {
        active: {
          label: "lbl_active",
          name: "active",
          placeholder: "lbl_active_placeholder",
        },
        categories: {
          label: "lbl_category",
          name: "categories",
          placeholder: "lbl_find_data_placeholder",
          decorator: [
            "categories",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description_placeholder",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        depreciate: {
          label: "lbl_depreciate",
        },
        book: {
          label: "lbl_asset_book",
          name: "book",
          placeholder: "lbl_find_data_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        method: {
          label: "lbl_method",
          name: "method",
          placeholder: "lbl_find_data_placeholder",
          decorator: ["method"],
        },
        lifeMonths: {
          label: "lbl_life_month",
          name: "lifeMonths",
          placeholder: "lbl_life_month_placeholder",
          decorator: [
            "lifeMonths",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        assetCost: {
          label: "lbl_asset_cost",
          name: "assetCost",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "assetCost",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        assetClearing: {
          label: "Asset Clearing",
          name: "assetClearing",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "assetClearing",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        depreciationExpense: {
          label: "Depreciation Expense",
          name: "depreciationExpense",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "depreciationExpense",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        accumulatedDepreciation: {
          label: "Accumulated Depreciation",
          name: "accumulatedDepreciation",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "accumulatedDepreciation",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      formRulesAssetCategoriesModal: {
        active: {
          label: "lbl_active",
          name: "active",
          placeholder: "lbl_active_placeholder",
        },
        categories: {
          label: "lbl_category",
          name: "categories",
          placeholder: "lbl_find_data_placeholder",
          decorator: ["categories"],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description_placeholder",
          decorator: ["description"],
        },
        depreciate: {
          label: "lbl_depreciate",
        },
        book: {
          label: "lbl_asset_book",
          name: "book",
          placeholder: "lbl_find_data_placeholder",
          decorator: ["book"],
        },
        method: {
          label: "lbl_method",
          name: "method",
          placeholder: "lbl_find_data_placeholder",
          decorator: ["method"],
        },
        lifeMonths: {
          label: "lbl_life_month",
          name: "lifeMonths",
          placeholder: "lbl_life_month_placeholder",
          decorator: ["lifeMonths"],
        },
        assetCost: {
          label: "lbl_asset_cost_account",
          name: "assetCost",
          placeholder: "lbl_find_data_placeholder",
          decorator: ["assetCost"],
        },
        assetClearing: {
          label: "lbl_asset_clearing_account",
          name: "assetClearing",
          placeholder: "lbl_find_data_placeholder",
          decorator: ["assetClearing"],
        },
        depreciationExpense: {
          label: "lbl_depreciation_expense_account",
          name: "depreciationExpense",
          placeholder: "lbl_find_data_placeholder",
          decorator: ["depreciationExpense"],
        },
        accumulatedDepreciation: {
          label: "lbl_accumulated_depreciation_account",
          name: "accumulatedDepreciation",
          placeholder: "lbl_find_data_placeholder",
          decorator: ["accumulatedDepreciation"],
        },
        revenueRentAccount: {
          label: "lbl_revenue_of_rent_account",
          name: "revenueRentAccount",
          placeholder: "lbl_find_data_placeholder",
          decorator: ["revenueRentAccount"],
        },
        revenueSalesAccount: {
          label: "lbl_revenue_of_sales_account",
          name: "revenueSalesAccount",
          placeholder: "lbl_find_data_placeholder",
          decorator: ["revenueSalesAccount"],
        },
      },
      formRules: {
        equipment: {
          label: "lbl_equipment",
          name: "equipment",
          placeholder: "lbl_equipment_placeholder",
          decorator: [
            "equipment",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        merk: {
          label: "lbl_brand",
          name: "merk",
          placeholder: "lbl_brand_placeholder",
          decorator: [
            "merk",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        type: {
          label: "SGU/NON SGU/CHILD",
          name: "type",
          placeholder: "lbl_type_placeholder",
          decorator: [
            "type",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      columnsTableAssetCategories: [
        {
          title: this.$t("lbl_asset_book"),
          dataIndex: "book",
          key: "book",
          width: 200,
          scopedSlots: { customRender: "book" },
          responsiveColSelect: [
            {
              name: "book",
              placeholder: this.$t("lbl_find_data_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as DataListAssetBook[],
              loading: false,
            },
            {
              name: "method",
              placeholder: this.$t("lbl_find_data_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "value",
              options: [] as ResponseListMaster[],
              loading: false,
            },
            {
              name: "assetCostAccount",
              placeholder: this.$t("lbl_find_data_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as CustomCoaCategory[],
              loading: false,
            },
            {
              name: "assetClearingAccount",
              placeholder: this.$t("lbl_find_data_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as CustomCoaCategory[],
              loading: false,
            },
            {
              name: "depreciationExpenseAccount",
              placeholder: this.$t("lbl_find_data_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as CustomCoaCategory[],
              loading: false,
            },
            {
              name: "accumulatedDepreciationAccount",
              placeholder: this.$t("lbl_find_data_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as CustomCoaCategory[],
              loading: false,
            },
            {
              name: "revenueSalesAccount",
              placeholder: this.$t("lbl_find_data_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as CustomCoaCategory[],
              loading: false,
            },
            {
              name: "revenueRentAccount",
              placeholder: this.$t("lbl_find_data_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as CustomCoaCategory[],
              loading: false,
            },
            {
              name: "uomId",
              placeholder: this.$t("lbl_find_data_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as ResponseListOfMasterUom[],
              loading: false,
            },
          ],
          responsiveColInput: [
            {
              name: "lifeMonths",
              placeholder: this.$t("lbl_life_month_placeholder"),
              style: "width: 100%;",
            },
          ],
          responsiveColSwitch: [
            {
              name: "depreciate",
              placeholder: this.$t("lbl_depreciate_placeholder"),
            },
            {
              name: "active",
              placeholder: this.$t("lbl_active_placeholder"),
            },
          ],
        },
        {
          title: this.$t("lbl_method"),
          dataIndex: "method",
          key: "method",
          width: 200,
          scopedSlots: { customRender: "method" },
        },
        {
          title: this.$t("lbl_depreciate"),
          dataIndex: "depreciate",
          key: "depreciate",
          width: 150,
          scopedSlots: { customRender: "depreciate" },
        },
        {
          title: this.$t("lbl_life_month"),
          dataIndex: "lifeMonths",
          key: "lifeMonths",
          width: 200,
          scopedSlots: { customRender: "lifeMonths" },
        },
        {
          title: this.$t("lbl_uom"),
          dataIndex: "uomId",
          key: "uomId",
          width: 200,
          scopedSlots: { customRender: "uomId" },
        },
        {
          title: this.$t("lbl_asset_cost_account"),
          dataIndex: "assetCostAccount",
          key: "assetCostAccount",
          width: 250,
          scopedSlots: { customRender: "assetCostAccount" },
        },
        {
          title: this.$t("lbl_asset_clearing_account"),
          dataIndex: "assetClearingAccount",
          key: "assetClearingAccount",
          width: 250,
          scopedSlots: { customRender: "assetClearingAccount" },
        },
        {
          title: this.$t("lbl_depreciation_expense_account"),
          dataIndex: "depreciationExpenseAccount",
          key: "depreciationExpenseAccount",
          width: 250,
          scopedSlots: { customRender: "depreciationExpenseAccount" },
        },
        {
          title: this.$t("lbl_accumulated_depreciation_account"),
          dataIndex: "accumulatedDepreciationAccount",
          key: "accumulatedDepreciationAccount",
          width: 260,
          scopedSlots: { customRender: "accumulatedDepreciationAccount" },
        },
        {
          title: this.$t("lbl_revenue_of_sales_account"),
          dataIndex: "revenueSalesAccount",
          key: "revenueSalesAccount",
          width: 260,
          scopedSlots: { customRender: "revenueSalesAccount" },
        },
        {
          title: this.$t("lbl_revenue_of_rent_account"),
          dataIndex: "revenueRentAccount",
          key: "revenueRentAccount",
          width: 260,
          scopedSlots: { customRender: "revenueRentAccount" },
        },
        {
          title: this.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          width: 150,
          scopedSlots: { customRender: "active" },
        },
      ],
      columns: [
        {
          title: this.$t("lbl_asset_category"),
          dataIndex: "assetCategories",
          key: "assetCategories",
          width: 200,
        },
        {
          title: this.$t("lbl_asset_book"),
          dataIndex: "book",
          key: "book",
          width: 200,
        },
        {
          title: this.$t("lbl_depreciate"),
          dataIndex: "depreciate",
          key: "depreciate",
          width: 200,
          scopedSlots: { customRender: "isEnable" },
        },
        {
          title: this.$t("lbl_method"),
          dataIndex: "bookMethod",
          key: "bookMethod",
          width: 200,
        },
        {
          title: this.$t("lbl_life_month"),
          dataIndex: "lifeMonths",
          key: "lifeMonths",
          width: 200,
        },
        {
          title: this.$t("lbl_asset_cost_account"),
          dataIndex: "assetCostAccount",
          key: "assetCostAccount",
          width: 200,
        },
        {
          title: this.$t("lbl_asset_depreciation_account"),
          dataIndex: "depreciationAccount",
          key: "depreciationAccount",
          width: 200,
        },
        {
          title: this.$t("lbl_accumulated_depreciation_account"),
          dataIndex: "accumulationAccount",
          key: "accumulationAccount",
          width: 200,
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          button: ["update"],
          width: 120,
        },
      ],
      dataSource: [] as CustomDataSourceListAssetCategory[],
      dataSourceAssetCategories: [
        {
          key: 0,
          book: undefined,
          method: undefined,
          depreciate: false,
          lifeMonths: undefined,
          assetCostAccount: undefined,
          assetClearingAccount: undefined,
          depreciationExpenseAccount: undefined,
          accumulatedDepreciationAccount: undefined,
          active: true,
          id: null,
          disabledInput: false,
          disabledSelect: false,
          disabledSwitch: [],
          uom: undefined,
        },
      ] as CustomDataSourceAssetCategories[],
    };
  },
  methods: {
    getListCoaTable(value) {
      let paramsListOfCoa = {
        page: 0,
        limit: 10,
        search: `isParent~false`,
      } as RequestQueryParamsModel;
      if (value)
        paramsListOfCoa.search =
          `code~*${value}*_OR_description~*${value}*_AND_` +
          paramsListOfCoa.search;
      // find column
      const findColumn = this.columnsTableAssetCategories.find(
        column => column.responsiveColSelect
      )?.responsiveColSelect;
      // list coa
      if (findColumn) {
        findColumn[2].loading = true;
        findColumn[3].loading = true;
        findColumn[4].loading = true;
        findColumn[5].loading = true;
        findColumn[6].loading = true;
        findColumn[7].loading = true;
      }
      settingsServices
        .listOfCoa(paramsListOfCoa, "")
        .then(data => {
          let tempData = data.data.map(dataMap => {
            return {
              name: `${dataMap.code} ${dataMap.description}`,
              id: dataMap.id,
            };
          });
          if (findColumn) {
            findColumn[2].options = tempData;
            findColumn[3].options = tempData;
            findColumn[4].options = tempData;
            findColumn[5].options = tempData;
            findColumn[6].options = tempData;
            findColumn[7].options = tempData;
          }
        })
        .finally(() => {
          if (findColumn) {
            findColumn[2].loading = false;
            findColumn[3].loading = false;
            findColumn[4].loading = false;
            findColumn[5].loading = false;
            findColumn[6].loading = false;
            findColumn[7].loading = false;
          }
        });
    },
    getBookMethodTable(value) {
      let paramsBookMethod = {
        name: `BOOK_METHOD`,
      } as RequestQueryParamsModel;
      if (value) paramsBookMethod.search = `value~*${value}*`;
      // find column
      const findColumn = this.columnsTableAssetCategories.find(
        column => column.responsiveColSelect
      )?.responsiveColSelect;
      let tempBookMethod = [] as ResponseListMaster[];

      if (findColumn) {
        findColumn[1].loading = true;
      }
      masterServices
        .listMaster(paramsBookMethod)
        .then(data => {
          tempBookMethod = data.map(dataFilter => {
            return {
              name: dataFilter.value,
              value: dataFilter.value,
              id: dataFilter.id,
            };
          });
          if (findColumn) {
            findColumn[1].options = tempBookMethod;
          }
        })
        .finally(() => {
          if (findColumn) {
            findColumn[1].loading = false;
          }
        });
    },
    getListOfAssetBookTable(value) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (value) params.search = `name~*${value}*`;
      // find column
      const findColumn = this.columnsTableAssetCategories.find(
        column => column.responsiveColSelect
      )?.responsiveColSelect;
      let filteredBook = [] as DataListAssetBook[];

      if (findColumn) {
        findColumn[0].loading = true;
      }
      assetsServices
        .listAssetBook(params)
        .then(data => {
          filteredBook = data.data.filter(
            dataMap =>
              !this.dataSourceAssetCategories.some(
                dataSome =>
                  dataSome.book === dataMap.name || dataSome.id === dataMap.id
              )
          );
          if (findColumn) {
            findColumn[0].options = filteredBook;
          }
        })
        .finally(() => {
          if (findColumn) {
            findColumn[0].loading = false;
          }
        });
    },
    handleSearchSelectTable(value, _key, colName) {
      switch (colName) {
        case "book":
          this.getListOfAssetBookTable(value);
          break;
        case "method":
          this.getBookMethodTable(value);
          break;
        case "uomId":
          this.getListUom(value);
          break;
        default:
          this.getListCoaTable(value);
          break;
      }
    },
    checkDescriptionOrNull(data: AccumulatedDepreciationAccount): string {
      if (data) return data.description;
      else return "";
    },
    checkUndefined(value): string {
      if (value && !value.includes("undefined")) return value;
      else return "";
    },
    handleSelectedCategories(value) {
      let params = {
        search: `categoryId~*${value}*`,
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      this.loadingTableAssetCategories = true;
      assetsServices
        .listAssetCategory(params)
        .then(data => {
          let tempData = [] as CustomDataSourceAssetCategories[];
          this.totalElementsAssetCategories = data.totalElements;
          if (data.data.length > 0) {
            let tempCoa: any = [];
            let tempUom: any = [];
            data.data.forEach((dataMap, index) => {
              if (index === 0) {
                this.formAssetCategories.setFieldsValue({
                  description: data.data[0].description,
                });
              }
              tempData = [
                ...tempData,
                {
                  uomId: dataMap.uomId,
                  book: dataMap.bookName,
                  method: dataMap.bookMethod,
                  depreciate: dataMap.depreciate,
                  lifeMonths: dataMap.lifeMonths,
                  assetCostAccount: this.checkUndefined(
                    dataMap.assetCostAccount?.id
                  ),
                  // assetCostAccount: dataMap.assetCostAccount.description,
                  assetClearingAccount: this.checkUndefined(
                    dataMap.assetClearingAccount?.id
                  ),
                  // assetClearingAccount: dataMap.assetClearingAccount.description,
                  depreciationExpenseAccount: this.checkUndefined(
                    dataMap.depreciationExpenseAccount?.id
                  ),
                  // depreciationExpenseAccount: dataMap.depreciationExpenseAccount.description,
                  accumulatedDepreciationAccount: this.checkUndefined(
                    dataMap.accumulatedDepreciationAccount?.id
                  ),
                  // accumulatedDepreciationAccount: dataMap.accumulatedDepreciationAccount.description,
                  revenueRentAccount: this.checkUndefined(
                    dataMap.revenueRentAccount?.id
                  ),
                  // revenueRentAccount: dataMap.revenueRentAccount.description,
                  revenueSalesAccount: this.checkUndefined(
                    dataMap.revenueSalesAccount?.id
                  ),
                  // revenueSalesAccount: dataMap.revenueSalesAccount.description,
                  key: tempData.length,
                  active: dataMap.active,
                  id: dataMap.id,
                  disabledInput: true,
                  disabledSelect: true,
                  disabledSwitch: ["depreciate", "active"],
                },
              ];
              const tempObjCoa1 = {
                id: this.checkUndefined(dataMap.assetCostAccount?.id),
                name: `${this.checkUndefined(
                  dataMap.assetCostAccount?.code
                )} ${this.checkUndefined(
                  dataMap.assetCostAccount?.description
                )}`,
              };
              const tempObjCoa2 = {
                id: this.checkUndefined(dataMap.assetClearingAccount?.id),
                name: `${this.checkUndefined(
                  dataMap.assetClearingAccount?.code
                )} ${this.checkUndefined(
                  dataMap.assetClearingAccount?.description
                )}`,
              };
              const tempObjCoa3 = {
                id: this.checkUndefined(dataMap.depreciationExpenseAccount?.id),
                name: `${this.checkUndefined(
                  dataMap.depreciationExpenseAccount?.code
                )} ${this.checkUndefined(
                  dataMap.depreciationExpenseAccount?.description
                )}`,
              };
              const tempObjCoa4 = {
                id: this.checkUndefined(
                  dataMap.accumulatedDepreciationAccount?.id
                ),
                name: `${this.checkUndefined(
                  dataMap.accumulatedDepreciationAccount?.code
                )} ${this.checkUndefined(
                  dataMap.accumulatedDepreciationAccount?.description
                )}`,
              };
              const tempObjCoa5 = {
                id: this.checkUndefined(dataMap.revenueRentAccount?.id),
                name: `${this.checkUndefined(
                  dataMap.revenueRentAccount?.code
                )} ${this.checkUndefined(
                  dataMap.revenueRentAccount?.description
                )}`,
              };
              const tempObjCoa6 = {
                id: this.checkUndefined(dataMap.revenueSalesAccount?.id),
                name: `${this.checkUndefined(
                  dataMap.revenueSalesAccount?.code
                )} ${this.checkUndefined(
                  dataMap.revenueSalesAccount?.description
                )}`,
              };
              tempCoa.push(tempObjCoa1);
              tempCoa.push(tempObjCoa2);
              tempCoa.push(tempObjCoa3);
              tempCoa.push(tempObjCoa4);
              tempCoa.push(tempObjCoa5);
              tempCoa.push(tempObjCoa6);
              tempUom.push({ id: dataMap.uomId, name: dataMap.uom });
            });
            const findColumn = this.columnsTableAssetCategories.find(
              column => column.responsiveColSelect
            )?.responsiveColSelect;
            if (findColumn) {
              findColumn[2].options = [
                ...findColumn[2].options,
                ...tempCoa,
              ].filter(
                (dataFilter, index, self) =>
                  index ===
                  self.findIndex(t => t.id === dataFilter.id && dataFilter.id)
              );
              findColumn[3].options = [
                ...findColumn[3].options,
                ...tempCoa,
              ].filter(
                (dataFilter, index, self) =>
                  index ===
                  self.findIndex(t => t.id === dataFilter.id && dataFilter.id)
              );
              findColumn[4].options = [
                ...findColumn[4].options,
                ...tempCoa,
              ].filter(
                (dataFilter, index, self) =>
                  index ===
                  self.findIndex(t => t.id === dataFilter.id && dataFilter.id)
              );
              findColumn[5].options = [
                ...findColumn[5].options,
                ...tempCoa,
              ].filter(
                (dataFilter, index, self) =>
                  index ===
                  self.findIndex(t => t.id === dataFilter.id && dataFilter.id)
              );
              findColumn[6].options = [
                ...findColumn[6].options,
                ...tempCoa,
              ].filter(
                (dataFilter, index, self) =>
                  index ===
                  self.findIndex(t => t.id === dataFilter.id && dataFilter.id)
              );
              findColumn[7].options = [
                ...findColumn[7].options,
                ...tempCoa,
              ].filter(
                (dataFilter, index, self) =>
                  index ===
                  self.findIndex(t => t.id === dataFilter.id && dataFilter.id)
              );
              findColumn[8].options = [
                ...findColumn[8].options,
                ...tempUom,
              ].filter(
                (dataFilter, index, self) =>
                  index ===
                  self.findIndex(t => t.id === dataFilter.id && dataFilter.id)
              );
            }
          } else {
            tempData = [
              {
                key: 0,
                id: null,
                active: true,
                depreciate: false,
                disabledInput: false,
                disabledSelect: false,
                disabledSwitch: [],
              },
            ];
          }
          this.dataSourceAssetCategories = tempData;
        })
        .finally(() => (this.loadingTableAssetCategories = false));
    },
    handleChangeMenu(value) {
      this.activeTabPane = value;
    },
    handleSwitchTable(checked, key, columnName) {
      this.dataSourceAssetCategories[key] = {
        ...this.dataSourceAssetCategories[key],
        [columnName]: checked,
      };
      this.dataSourceAssetCategories = this.dataSourceAssetCategories.slice();
    },
    handleInput(value, key, _column, columnName) {
      this.dataSourceAssetCategories[key] = {
        ...this.dataSourceAssetCategories[key],
        [columnName]: value,
      };
      this.dataSourceAssetCategories = this.dataSourceAssetCategories.slice();
    },
    handleSelect(value, key, columnName) {
      this.dataSourceAssetCategories[key][columnName] = value;
      this.dataSourceAssetCategories = this.dataSourceAssetCategories.slice();
    },
    onSelectChange(value) {
      this.selectedRowKeys = value;
    },
    showConfirmation() {
      if (this.selectedRowKeys.length > 0) {
        let messageDescription = "";
        // check if id has a data, can't delete
        this.selectedRowKeys.forEach(data => {
          if (this.dataSourceAssetCategories[data].id) {
            messageDescription =
              "You can't delete data that has been registered";
          }
        });
        if (messageDescription) {
          this.$notification.error({
            message: "Error",
            description: messageDescription,
          });
        } else {
          this.$confirm({
            title: this.$t("confirmation_delete"),
            content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
            onOk: () => {
              this.dataSourceAssetCategories =
                this.dataSourceAssetCategories.filter((data, index) => {
                  if (!this.selectedRowKeys.includes(index)) {
                    return data;
                  } else {
                    return;
                  }
                });
              this.totalElementsAssetCategories =
                this.dataSourceAssetCategories.length;
              this.dataSourceAssetCategories =
                this.dataSourceAssetCategories.map((data, index) => {
                  return { ...data, key: index };
                });
              this.selectedRowKeys = [];
            },
            onCancel() {
              return;
            },
          });
        }
      } else {
        this.$notification.error({
          message: "Error",
          description: "Select at least one row to delete",
        });
      }
    },
    handleAddRow() {
      this.dataSourceAssetCategories = [
        ...this.dataSourceAssetCategories,
        {
          key: this.dataSourceAssetCategories.length,
          depreciate: false,
          id: null,
          active: true,
          disabledInput: false,
          disabledSelect: false,
          disabledSwitch: [],
        },
      ];
      this.totalElementsAssetCategories += 1;
    },
    handleSelectBook(data) {
      this.formAssetCategories.setFieldsValue({
        method: data.split("@")[1],
      });
    },
    ResetFilter() {
      this.search = "";
      this.firstSearch = false;
      this.getListOfCategories();
    },
    responsePageSizeChangeAssetCategories(response: ResponsePagination): void {
      this.limitAssetCategories = response.size;
      this.pageAssetCategories = 1;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfCategories();
    },
    responseCurrentPageChangeAssetCategories(
      response: ResponsePagination
    ): void {
      this.pageAssetCategories = response.page;
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfCategories();
    },
    reponseSearchInput(value) {
      if (value)
        this.search = `description~*${value}*_OR_assetCostAccount.description~*${value}*_OR_assetCostAccount.code~*${value}*_OR_depreciationExpenseAccount.description~*${value}*_OR_accumulatedDepreciationAccount.description~*${value}*_OR_categoryId~*${value}*`;
      else this.search = "";
      this.firstSearch = true;
      this.getListOfCategories();
    },
    reponseDeleteTable(response) {
      assetsServices
        .deleteAssetCategory(response.data.id)
        .then(() => {
          this.$notification.success({
            description: Messages.DELETE_SUCCESS,
            message: "Success",
            duration: 30,
          });
          this.getListOfCategories();
        })
        .catch(() =>
          this.$notification.error({
            description: Messages.DELETE_FAIL,
            message: "Error",
            duration: 30,
          })
        );
    },
    reponseEditTable(response) {
      this.mode = "Edit";
      this.visible = true;
      this.idForUpdate = response.data.id;
      if (this.search) {
        this.search += `_OR_secureId~*${response.data.id}*`;
      } else {
        this.search = `secureId~*${response.data.id}*`;
      }
      this.page = 1;
      this.getListOfCategories(response.data.id);
    },
    handleSearchCoa(value) {
      let paramsListOfCoa = {
        page: 0,
        limit: 10,
        search: `isParent~false`,
      } as RequestQueryParamsModel;
      if (value)
        paramsListOfCoa.search =
          `code~*${value}*_OR_description~*${value}*_AND_` +
          paramsListOfCoa.search;
      // list coa
      settingsServices.listOfCoa(paramsListOfCoa, "").then(data => {
        this.dataCoa = data;
      });
    },
    getListEquipment(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `first~*${valueSearch}*`;

      assetsServices.listSegmentParent(params).then(data => {
        this.dataEquipment = data;
      });
    },
    cancelFormCategory() {
      this.form.resetFields();
    },
    cancelFormAssetCategory() {
      this.formAssetCategories.resetFields();
      this.dataSourceAssetCategories = [
        {
          key: 0,
          id: null,
          active: true,
          depreciate: false,
          disabledInput: false,
          disabledSelect: false,
          disabledSwitch: [],
        },
      ];
    },
    cancelFormEquipment() {
      this.formEquipment.resetFields();
    },
    submitFormEquipment(e) {
      e.preventDefault();
      this.formEquipment.validateFields((err, res) => {
        if (err) return;
        const payload = {
          first: res.equipment,
          second: null,
        } as RequestCreateSegment;
        this.loadingEquipment = true;
        assetsServices
          .createSegment(payload)
          .then(() => {
            this.$notification.success({
              description: Messages.CREATE_SUCCESS,
              message: "Success",
              duration: 30,
            });
            this.formEquipment.resetFields();
          })
          .catch(() =>
            this.$notification.error({
              description: Messages.CREATE_FAIL,
              message: "Error",
              duration: 30,
            })
          )
          .finally(() => (this.loadingEquipment = false));
      });
    },
    submitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, res) => {
        if (err) return;
        const payload = {
          segments: [
            {
              first: res.equipment,
              second: null,
            },
            {
              first: res.merk,
              second: res.type,
            },
          ],
        } as RequestCategoryAsset;

        this.loading = true;
        assetsServices
          .createCategory(payload)
          .then(() => {
            this.$notification.success({
              description: Messages.CREATE_SUCCESS,
              message: "Success",
              duration: 30,
            });
            this.form.resetFields();
          })
          .finally(() => (this.loading = false));
      });
    },
    checkClearingAccount(
      assetClearingAccount,
      params
    ): Promise<ResponseListOfCoa> | null {
      if (assetClearingAccount)
        return settingsServices.listOfCoa(
          params,
          `/${assetClearingAccount.id}`
        );
      else return null;
    },
    checkerIdOrNull(data) {
      if (data) return data.id;
      else return null;
    },
    getListOfCategories(id?) {
      let params = {
        page: this.page - 1,
        limit: this.limit,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.firstSearch) params.page = 0;

      this.loadingTable = true;
      assetsServices
        .listAssetCategory(params)
        .then(response => {
          if (id) {
            let paramsListOfCoa = {
              page: 0,
              limit: 10,
            } as RequestQueryParamsModel;
            const promise1 = this.checkClearingAccount(
              response.data[0].assetCostAccount,
              paramsListOfCoa
            );
            const promise2 = this.checkClearingAccount(
              response.data[0].assetClearingAccount,
              paramsListOfCoa
            );
            const promise3 = this.checkClearingAccount(
              response.data[0].depreciationExpenseAccount,
              paramsListOfCoa
            );
            const promise4 = this.checkClearingAccount(
              response.data[0].accumulatedDepreciationAccount,
              paramsListOfCoa
            );
            const promise5 = this.checkClearingAccount(
              response.data[0].revenueRentAccount,
              paramsListOfCoa
            );
            const promise6 = this.checkClearingAccount(
              response.data[0].revenueSalesAccount,
              paramsListOfCoa
            );

            Promise.all([
              promise1,
              promise2,
              promise3,
              promise4,
              promise5,
              promise6,
            ]).then(value => {
              value.forEach(data => {
                if (data) {
                  let temp = {
                    data: [...this.dataCoa.data, data],
                    currentPage: this.dataCoa.currentPage,
                    totalPages: this.dataCoa.totalPages,
                    totalElements: this.dataCoa.totalElements + 1,
                  } as ResponseListOfCoa;
                  this.dataCoa = temp;
                }
              });
            });
            this.depreciate = response.data[0].depreciate;
            this.active = response.data[0].active;
            this.formAssetCategoriesModal.setFieldsValue({
              categories: response.data[0].category.id,
              description: response.data[0].description,
              book: response.data[0].bookName,
              method: response.data[0].bookMethod,
              lifeMonths: response.data[0].lifeMonths,
              assetCost: this.checkerIdOrNull(
                response.data[0]?.assetCostAccount
              ),
              assetClearing: this.checkerIdOrNull(
                response.data[0]?.assetClearingAccount
              ),
              depreciationExpense: this.checkerIdOrNull(
                response.data[0]?.depreciationExpenseAccount
              ),
              accumulatedDepreciation: this.checkerIdOrNull(
                response.data[0]?.accumulatedDepreciationAccount
              ),
              revenueRentAccount: this.checkerIdOrNull(
                response.data[0]?.revenueRentAccount
              ),
              revenueSalesAccount: this.checkerIdOrNull(
                response.data[0]?.revenueSalesAccount
              ),
            });
          } else {
            let tempDataSource = [] as CustomDataSourceListAssetCategory[];
            response.data.forEach(value => {
              function toCamelCase(str) {
                return str
                  .split(" ")
                  .map(function (word, index) {
                    return (
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                    );
                  })
                  .join(" ");
              }

              let idCategories = toCamelCase(value.category.id).split(".");

              idCategories = idCategories.map(dataMap => {
                let temp = dataMap.split("");
                if (temp.length > 0) {
                  temp[0] = temp[0].toUpperCase();
                  dataMap = temp.join("");
                }
                return dataMap;
              });

              idCategories[idCategories.length - 1] =
                idCategories[idCategories.length - 1].toUpperCase();

              let tempData = {
                assetCategories: idCategories.join("."),
                book: value.bookName,
                depreciate: value.depreciate,
                bookMethod: value.bookMethod,
                lifeMonths: value.lifeMonths,
                assetCostAccount: `${value.assetCostAccount.code}-${value.assetCostAccount.description}`,
                depreciationAccount:
                  value.depreciationExpenseAccount.description,
                accumulationAccount:
                  value.accumulatedDepreciationAccount.description,
                id: value.id,
              } as CustomDataSourceListAssetCategory;

              tempDataSource = [...tempDataSource, tempData];
            });
            this.dataSource = tempDataSource;
            this.totalData = response.totalElements;
            this.firstSearch = false;
          }
        })
        .finally(() => (this.loadingTable = false));
    },
    getListCategories(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `categoryId~*${valueSearch}*`;
      this.loadingListCategories = true;
      assetsServices
        .listCategory(params)
        .then(response => {
          let tempDataSegments = [] as CustomListCategory[];
          response.data.forEach(data => {
            let tempSegment = {
              id: data.id,
              first: "",
              second: "",
            };
            data.segments.forEach((dataSegment, index) => {
              if (dataSegment.first) {
                tempSegment.first += dataSegment.first;
              }
              if (dataSegment.second) {
                tempSegment.second += dataSegment.second;
              }
              if (index % 2 === 0) {
                tempSegment.first += ".";
              }
            });
            tempDataSegments.push(tempSegment);
          });
          this.dataListCategory = tempDataSegments;
          this.dataListCategory = this.dataListCategory.slice();
        })
        .finally(() => (this.loadingListCategories = false));
    },
    getListUom(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch) params.search = `unit~*${valueSearch}*`;
      const findColumn = this.columnsTableAssetCategories.find(
        column => column.responsiveColSelect
      )?.responsiveColSelect;
      settingsServices.listOfMasterUom(params, "").then(response => {
        if (findColumn) {
          findColumn[8].options = response.data;
        }
      });
    },
    handleCancelModal() {
      this.visible = false;
      this.search = "";
      this.page = 1;
      this.formAssetCategoriesModal.resetFields();
      this.getListOfCategories();
    },
    submitFormAssetCategoriesModal() {
      this.formAssetCategoriesModal.validateFields((err, res) => {
        if (err) return;
        const payload = {
          accumulatedDepreciationAccountId: res.accumulatedDepreciation,
          assetClearingAccountId: res.assetClearing,
          assetCostAccountId: res.assetCost ? res.assetCost : null,
          bookMethod: res.method,
          bookType: res.book,
          categoryId: res.categories,
          depreciate: this.depreciate,
          depreciationExpenseAccountId: res.depreciationExpense,
          description: res.description,
          lifeMonths: res.lifeMonths,
          active: this.active,
        } as RequestAssetCategory;

        assetsServices
          .updateAssetCategory(payload, this.idForUpdate)
          .then(() => {
            this.$notification.success({
              description: Messages.UPDATE_SUCCESS,
              message: "Success",
              duration: 30,
            });
            this.visible = false;
            this.search = "";
            this.page = 1;
            this.getListOfCategories();
            this.formAssetCategoriesModal.resetFields();
          })
          .catch(() =>
            this.$notification.error({
              description: Messages.UPDATE_FAIL,
              message: "Error",
              duration: 30,
            })
          );
      });
    },
    checkAndReturnString(value): string {
      if (value) return value;
      else return "";
    },
    checkAndReturnNumber(value): number {
      if (value) return value;
      else return 0;
    },
    submitFormAssetCategories() {
      this.formAssetCategories.validateFields((err, res) => {
        if (err) return;

        let payload = [] as RequestCreateAssetCategory[];

        let descriptionMessage = "";

        if (this.dataSourceAssetCategories.length > 0) {
          // checker
          this.dataSourceAssetCategories.forEach(data => {
            const {
              assetClearingAccount,
              book,
              accumulatedDepreciationAccount,
              method,
              lifeMonths,
              assetCostAccount,
              depreciationExpenseAccount,
              revenueSalesAccount,
              revenueRentAccount,
            } = data;
            if (
              !assetClearingAccount ||
              !book ||
              !accumulatedDepreciationAccount ||
              !method ||
              !lifeMonths ||
              !assetCostAccount ||
              !depreciationExpenseAccount ||
              !revenueSalesAccount ||
              !revenueRentAccount
            ) {
              descriptionMessage = "Make sure you have filled all the forms";
            }
          });
          if (!descriptionMessage) {
            payload = this.dataSourceAssetCategories.map(data => {
              const {
                assetClearingAccount,
                book,
                active,
                accumulatedDepreciationAccount,
                method,
                depreciate,
                lifeMonths,
                assetCostAccount,
                depreciationExpenseAccount,
                revenueSalesAccount,
                revenueRentAccount,
                id,
                uomId,
              } = data;
              return {
                accumulatedDepreciationAccountId: this.checkAndReturnString(
                  accumulatedDepreciationAccount
                ),
                assetClearingAccountId:
                  this.checkAndReturnString(assetClearingAccount),
                assetCostAccountId: this.checkAndReturnString(assetCostAccount),
                depreciationExpenseAccountId: this.checkAndReturnString(
                  depreciationExpenseAccount
                ),
                revenueSalesAccountId:
                  this.checkAndReturnString(revenueSalesAccount),
                revenueRentAccountId:
                  this.checkAndReturnString(revenueRentAccount),
                bookId: this.checkAndReturnString(book),
                lifeMonths: this.checkAndReturnNumber(lifeMonths),
                method: this.checkAndReturnString(method),
                active: active,
                categoryId: res.categories,
                depreciate: depreciate,
                description: res.description,
                id: id,
                uomId: uomId,
              };
            });
          }
        } else {
          descriptionMessage = "Accounts can't empty";
        }

        if (descriptionMessage) {
          this.$notification.error({
            message: "Error",
            description: descriptionMessage,
          });
        } else {
          this.loading2 = true;
          assetsServices
            .createAssetCategory(payload)
            .then(() => {
              this.$notification.success({
                description: Messages.CREATE_SUCCESS,
                message: "Success",
                duration: 30,
              });
              this.formAssetCategories.resetFields();
              this.dataSourceAssetCategories = [
                {
                  key: 0,
                  id: null,
                  active: true,
                  depreciate: false,
                  disabledInput: false,
                  disabledSelect: false,
                  disabledSwitch: [],
                },
              ];
            })
            .catch(() =>
              this.$notification.error({
                description: Messages.CREATE_FAIL,
                message: "Error",
                duration: 30,
              })
            )
            .finally(() => (this.loading2 = false));
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.handleSearchCoa("");
    this.getListEquipment("");
    this.getListCategories("");
    this.getListOfCategories();
    this.getBookMethodTable("");
    this.getListOfAssetBookTable("");
    this.getListCoaTable("");
    this.getListUom("");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
